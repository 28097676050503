import React from "react";

import { Col, Row, Card, CardBody } from "reactstrap";


const TermsAndConditionsV2 = () => {


    return (
        <>

            <ul className="fs--2" style={{ listStyleType: 'upper-alpha', textAlign: 'left', fontSize: 10 }}>
                <li>VDA Indemnification. VDA shall be responsible only for the work performed directly by its employees or those persons retained by VDA to perform work in conjunction with this project and shall defend, indemnify and hold harmless the Client against claims, damages, actual out-of-pocket costs or actual out-of-pocket expenses (including, without limitation, reasonable attorney fees) arising out of such performance.</li>
                <li>Client Indemnification. VDA shall not be responsible for the acts or omissions of the Client or any of the Client’s other consultants, contractor(s), sub-consultant(s), vendor(s) their agents or employees, or other persons performing any of the work, and Client shall defend, indemnify and hold harmless VDA against claims, damages, actual out of pocket costs or actual out of pocket expenses (including, without limitation, reasonable attorney fees) arising out of any of the foregoing.</li>
                <li>Invoicing: It is expected that invoices will be paid within thirty (30) days of rendering.  Two percent (2.0%) per interest will be compounded monthly and added to any invoice that is outstanding for over 120 days. The Client shall be responsible for the payment of sales and use taxes which may be imposed or assessed by the State of New York or any other local jurisdiction in connection with the services provided by VDA.  The Client shall indemnify and hold harmless VDA and its agents and employees against any such sales and use taxes, claims or liabilities. In the event the Client fails to pay outstanding invoices within one hundred twenty (120) days, and it is turned over to a third party for collection, Client agrees to pay all of VDA’s costs and expenses of collection, including without limitation, reasonable attorney’s fees and expenses incurred as a result of the foregoing.</li>
                <li>Overtime: In the event that the inspection/test exceeds the four (4) hour allotted time per device and/or requires an inspection time/day different from our normal working hours (as noted below), overtime rates may apply.</li>
                <li>Penalties: VDA shall not be responsible for any penalties imposed as a result of the performing agency and/or owner’s failure to comply with mandated procedures or local law.</li>
                <li>DOBNOW: All required DOBNOW signatures must be completed promptly upon request by the elevator contractor or VDA. The Client is responsible, and will indemnify VDA, for any late fees or penalties incurred due to delayed/late filings. Furthermore, Client agrees to have a signed agreement in place with their licensed elevator maintenance contractor for the performance of all required category tests.</li>
                <li>Scheduling: Required scheduling and associated governing authority notifications shall be completed by the Client’s approved Agency (Contractor) responsible for performing the Periodic Testing and coordinated with VDA. prior to confirming the scheduling with all parties concerned and the Department of Buildings Elevator Division.  Five (5) year elevator tests and one (1) year escalator tests shall be scheduled ten (10) days prior to performance of the procedure with the New York City Department of Buildings by the agency (contractor) performing the inspection/test procedure(s).  </li>
                <li>Fire Emergency/Class E/Emergency Power Testing: Witnessing and certification of tests/inspections do not include operation of fire emergency operating/signal “Class E” systems or emergency power systems.  </li>
                <li>Unsafe Conditions: The inspection/testing procedures as required by law pose extraordinary operating conditions that may reveal component failures or damage to the equipment as a result of the examination requirements.  It is understood and agreed that the third-party witnessing inspection agency and employees shall not be held responsible for any such failures and no damages of any kind shall be assessed against the witnessing inspection agency, affiliates or employees. Client is responsible for shutting an elevator down if a dangerous condition is uncovered until proper repairs can be made.</li>
                <li>Elevator Contractor Cancellation: In the event that the Performing Agency (Elevator Contractor) must cancel a test within the timeframes identified in Article VI of the base proposal, the same fees will apply.  Please note: the Owner is responsible for paying said fees.</li>
                <li>Force Majeure. Neither VDA nor Client shall be liable for any loss, damage, detention or delay resulting from any cause whatsoever beyond their reasonable control or resulting from a force majeure, including, without limitation, fire, flood, strike, lockout, pandemic or epidemic, civil or military authority, insurrection or war.</li>
                <li>Waiver of Damages. Neither VDA nor Client shall be responsible for any consequential damages or punitive damages, or damages based on a claim of loss of business, loss of business opportunity, or loss of revenue.</li>
                <li>Copyright/Deliverables. Documents, reports, and specifications prepared or created by VDA as Work Product(s), in connection with the Agreement, are not to be used on other projects or extensions to this project and will be protected under Copyright © 2024, except by written agreement and with suitable compensation to VDA. Notwithstanding anything to the contrary, the parties acknowledge and agree that VDA shall have no responsibility or liability in connection with claims resulting from edits, additions, subtractions or revisions to the Work Product(s) made by Client or third parties without the written consent of VDA.  </li>
                <li>Insurance. During the term of this Agreement, VDA will maintain the following insurance: Workers’ Compensation Insurance (statutory limits), Commercial General Liability with a limit of $1,000,000 per occurrence ($2,000,000 aggregate), Automobile Liability with a $1,000,000 combined single-limit,  Umbrella Liability with a limit of $5,000,000.00 (follows form on the underlying CGL & Auto policies), Professional Liability with a limit of $5,000,000 per claim with a maximum deductible not to exceed $150,000. Upon request, insurance certificates will be submitted to the Client. </li>
                <li>Assignment. Neither party hereto shall assign any of its rights or delegate any of its obligations hereunder without the prior written consent of the other party; provided, however, that VDA may assign its rights or delegate its obligations, in whole or in part, without such consent, to (a) any of its subsidiaries or affiliates, or (b) an entity that acquires all or substantially all of (i) the business or equity of VDA or (ii) the assets of VDA to which this Agreement pertains, in each case, whether by merger, reorganization, acquisition, sale, operation of law or otherwise. Any purported assignment or delegation in violation of this Section shall be null and void. In the event VDA desires to delegate work to a Subconsultant, and receives written approval from Client, all Terms and Conditions contained herein shall apply to such Subconsultant.</li>
                <li>Termination/Suspension. This Agreement may be terminated or suspended by either party upon seven (7) days written notice thereof to the other party. In the event this Agreement is terminated by Client for any reason, Client will pay VDA for all services rendered prior to and including the effective date of such termination. In the event the services are inactive or suspended, for a period of or exceeding 180 days, VDA and Client shall meet and equitably adjust the fees and schedule for the services not rendered.</li>
                <li>Binding of Heirs. This Agreement may be executed in multiple counterparts, each of which shall be deemed an original but all of which shall constitute one and the same instrument. This Agreement is binding upon the original parties and their respective heirs, assigns, administrators, executors or legal representatives (as permitted by this Agreement).</li>
                <li>Severability. Should one or more provisions within the Agreement be held invalid, illegal or unenforceable, the Agreement will be construed to survive such a holding and the invalid, illegal or unenforceable provisions will not affect any other provisions of the Agreement.</li>
                <li>Extent of Agreement. This writing constitutes the sole intention and agreement of the parties. Any and all prior oral and/or written agreements or understandings between the parties are hereby null and void with respect to the subject matter hereof. </li>
                <li>Force and Effect. The services set forth in this Agreement shall automatically terminate and be of no further force and effect unless the Client returns a duly executed counterpart of this Agreement to VDA within sixty (60) days of the date first set forth above, time being of the essence.</li>
                <li>Notices. All communications under this Agreement shall be in writing, and shall be deemed to be sufficiently given and delivered by the party (i) on the date presented and a receipt is given if sent by Federal Express or other nationally recognized courier, or (ii) on the fourth (4th) day after having been mailed by certified mail, return receipt requested, to a party at the addresses set forth on the cover page of this Agreement, or to such other address as such party may designate to the other party in writing.</li>
                <li>Forum Selection. Any and all disputes arising out of or relating to this agreement (including any dispute regarding its validity, enforceability, or interpretation) shall be brought exclusively in the state and federal courts where the project is located, and the parties to this agreement hereby consent to the exclusive jurisdiction of those courts.</li>

            </ul>

        </>
    );
};

export default TermsAndConditionsV2;
