import React, { useRef, useState, useEffect } from "react";
import { useHistory, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Col, FormGroup, Input, Label, Row, Alert, Card, CardBody } from "reactstrap";
import ReCAPTCHA from "react-google-recaptcha";
import BootstrapTable from 'react-bootstrap-table-next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Badge from 'reactstrap/es/Badge';
import Scrollbar from 'react-scrollbars-custom';
import classNames from 'classnames';

// components
import Logo from "../../common/Logo";
import DeviceModal from '../components/_DeviceModal';

// services
import * as newProposalApi from '../../services/newProposalApi';
import * as newProposalActions from '../../redux/actions/newProposalActions';
import moment from "moment";
import TermsAndConditionsV2 from "./_TermsAndConditionsV2";

const ProposalV2 = () => {

    let { id } = useParams();
    let history = useHistory();
    let dispatch = useDispatch();

    const { proposal, lines, expiringLines, underContractLines } = useSelector(state => state.newProposalReducer);

    const [logo, setLogo] = useState(require("../../assets/img/vda_logo.png"));
    const [showLoader, setShowLoader] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [canEdit, setCanEdit] = useState(false);
    const [captchaValue, setCaptchaValue] = useState(false);
    const [errorMessage, setErrorMessage] = useState('error message section');
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [auditDobNowEmail, setAuditDobNowEmail] = useState('');
    const [agentSignature, setAgentSignature] = useState('');
    const [agreedToExpeditingFee, setAgreedToExpediteFee] = useState(null);
    const [view, setView] = useState('expiring');
    const [propLines, setPropLines] = useState([]);
    const [originalLines, setOriginalLines] = useState([]);

    const [localPricing, setLocalPricing] = useState({
        cat1EscalatorFee: '',
        cat1ElevatorFee: '',
        cat1LiftFee: '',
        cat1FilingFee: '',
        cat5ElevatorFee: '',
        cat5LiftFee: '',
        cat5FilingFee: '',
        periodicElevatorFee: '',
        periodicFilingFee: '',
        dismantleElevatorFee: '',
        dismantleFilingFee: '',
        rescheduleFee: '',
        overtimeRate: '',
        fireServiceFee: '',
        aocFee: '',
        expeditingFee: '',
        qceFee: ''
    });

    useEffect(() => {

        if (!id) {
            // we don't have a proposal id in the url
            history.push('/notFound');
        }

        loadProposal();

    }, []);



    const loadProposal = async () => {

        setShowLoader(true);

        if (!id) {
            // we don't have a proposal id

            history.push('/notFound');
        }

        let manifest = await newProposalApi.getNewManifestV2(id);

        if (manifest.status !== 200) {

            history.push('/notFound');
        } else {


            let tempExpediting = null;
            if (manifest.data.proposal.agreedToExpeditingFee === true) {
                tempExpediting = true;
            }

            if (manifest.data.proposal.agreedToExpeditingFee === false) {
                tempExpediting = false;
            }

            setAuditDobNowEmail(manifest.data.proposal.auditDobNowEmail);
            setAgentSignature(manifest.data.proposal.agentSignature);
            setAgreedToExpediteFee(tempExpediting);
            setCanEdit(manifest.data.proposal.canEdit);


            dispatch(newProposalActions.setNewProposal(manifest.data))
                .then((resp) => {

                    loadView('expiring');
                    setView('expiring');
                    setShowLoader(false);
                    setLocalPricing({
                        cat1ElevatorFee: resp.proposal.renewalCat1ElevatorFee,
                        cat1EscalatorFee: resp.proposal.renewalCat1EscalatorFee,
                        cat1LiftFee: resp.proposal.renewalCat1LiftFee,
                        cat1FilingFee: resp.proposal.renewalCat1FilingFee,

                        cat5ElevatorFee: resp.proposal.renewalCat5ElevatorFee,
                        cat5LiftFee: resp.proposal.renewalCat5LiftFee,
                        cat5FilingFee: resp.proposal.renewalCat5FilingFee,

                        periodicElevatorFee: resp.proposal.renewalPeriodicElevatorFee,
                        periodicEscalatorFee: resp.proposal.renewalPeriodicEscalatorFee,
                        periodicLiftFee: resp.proposal.renewalPeriodicLiftFee,
                        periodicFilingFee: resp.proposal.renewalPeriodicFilingFee,

                        dismantleElevatorFee: resp.proposal.renewalDismantleElevatorFee,
                        dismantleEscalatorFee: resp.proposal.renewalDismantleEscalatorFee,
                        dismantleLiftFee: resp.proposal.renewalDismantleLiftFee,

                        rescheduleFee: resp.proposal.renewalRescheduleFee,
                        overtimeRate: resp.proposal.renewalOvertimeRate,
                        fireServiceFee: resp.proposal.renewalFireServiceFee,

                        aocFee: resp.proposal.renewalAocFee,
                        expeditingFee: resp.proposal.renewalExpeditingFee,
                        qceFee: resp.proposal.renewalQceFee,
                        contractTerm: resp.proposal.contractTerm,
                    });
                    setOriginalLines(resp.lines);

                    setPropLines(resp.expiringLines);
                });
        }
    }


    const loadView = (myView) => {

        const currentYear = new Date().getFullYear();

        switch (myView) {
            case 'all':
                setPropLines(lines);
                break;
            case 'current':
                setPropLines(lines.filter(f => f.agreementThroughYear > currentYear));
                break;
            case 'expiring':
                setPropLines(lines.filter(f => f.agreementThroughYear == currentYear));
                break;
        }

        setView(myView);
    }


    const captchaOptions = {
        siteKey: "6LeX6-AZAAAAAL6CT39-JX-G0CivQeDwXZDyqX1A",
        captchaRef: useRef("test"),
    };

    const handleCaptcha = (captchaVal) => {
        setCaptchaValue(captchaVal ? true : false);
    };

    const validateForm = () => {

        // make sure the status of the lines has something checked
        if (lines.some(c => c.statusId == 0)) {
            setErrorMessage('Please Approve or Decline each of the buildings.');
            setShowErrorMessage(true);
            return false;
        }

        if (!agentSignature) {
            setErrorMessage('You must sign the proposal.');
            setShowErrorMessage(true);
            return false;
        }

        if (agreedToExpeditingFee !== true && agreedToExpeditingFee !== false) {
            setErrorMessage('Please select an option for the Expedite Filing Fee.');
            setShowErrorMessage(true);
            return false;
        }

        //validate captcha
        if (!captchaValue) {
            setErrorMessage('Please verify that you are not a robot');
            setShowErrorMessage(true);
            return false;
        };

        setErrorMessage('');
        setShowErrorMessage(false);
        return true;
    };

    const handleSubmit = async () => {

        setShowErrorMessage(validateForm());
        if (validateForm()) {
            setShowLoader(true);

            let completeProposal = { ...proposal };

            completeProposal.auditDobNowEmail = auditDobNowEmail;
            completeProposal.agentSignature = agentSignature;

            completeProposal.agreedToExpeditingFee = agreedToExpeditingFee;
            completeProposal.statusId = 3; // force a submit status change

            let completedLines = [...lines];

            // gather the proposal
            let proposalToSubmit = {
                proposal: completeProposal,
                lines: completedLines
            };

            await newProposalApi.submit(proposalToSubmit);
            setShowLoader(false);
            history.push('/Complete');
        }
    };

    const launchEditModal = (rowToUpdate) => {
        dispatch(newProposalActions.setNewProposalDetail(rowToUpdate));
        setShowModal(true);
    }

    const approveLine = (rowToUpdate) => {
        // set the status of the single line to 1 or approved
        rowToUpdate.statusId = 1;

        dispatch(newProposalActions.updateNewLine(rowToUpdate));
        setPropLines(expiringLines);
    }
    const declineLine = (rowToUpdate) => {
        rowToUpdate.statusId = 2;
        dispatch(newProposalActions.updateNewLine(rowToUpdate));
    }

    const updateAll = (status) => {
        let allLines = lines.map(item => {
            if (item.agreementThroughYear <= moment(new Date).year()) {

                return { ...item, statusId: status };
            } else {
                return { ...item, statusId: 1 };
            }
        });

        dispatch(newProposalActions.updateNewAllLines(allLines));
    }

    const actionFormatter = (cell, item) => {

        // decide based on line status
        let approvedColor = 'secondary';
        let declinedColor = 'secondary';
        let approveOutline = true;
        let declineOutline = true;

        switch (item.statusId) {
            case 1:

                approvedColor = 'success';
                approveOutline = false;
                break;
            case 2:
                declinedColor = 'danger';
                declineOutline = false;
                break;
        };


        if (view === 'expiring') {
            return (
                <>
                    <Button size="sm" outline={true} className="fs--1 mr-2" color="info" disabled={!canEdit} onClick={() => launchEditModal(item)}>
                        Edit
                    </Button>
                    <Button size="sm" outline={approveOutline} className="fs--1 mr-2" color={approvedColor} disabled={!canEdit} onClick={() => approveLine(item)}>
                        <FontAwesomeIcon icon="check" className="fs--4" />
                    </Button>
                    <Button size="sm" outline={declineOutline} className="fs--1" color={declinedColor} disabled={!canEdit} onClick={() => declineLine(item)}>
                        <FontAwesomeIcon icon="ban" className="fs--4" />
                    </Button>
                </>
            );
        } else {
            return (

                <Button size="sm" outline={true} className="fs--1 mr-2" color="info" disabled={!canEdit} onClick={() => launchEditModal(item)}>
                    Edit
                </Button>
            );
        };
        // return (
        //     <>
        //         <Button size="sm" outline={true} className="fs--1 mr-2" color="info" disabled={!canEdit} onClick={() => launchEditModal(item)}>
        //             Edit
        //         </Button>
        //         <Button size="sm" outline={approveOutline} className="fs--1 mr-2" color={approvedColor} disabled={!canEdit} onClick={() => approveLine(item)}>
        //             <FontAwesomeIcon icon="check" className="fs--4" />
        //         </Button>
        //         <Button size="sm" outline={declineOutline} className="fs--1" color={declinedColor} disabled={isDisabled} onClick={() => declineLine(item)}>
        //             <FontAwesomeIcon icon="ban" className="fs--4" />
        //         </Button>
        //     </>
        // );


    };

    const corpNameFormat = (cell, item) => {

        let corpName = item.corpName;
        let auditCorpName = item.auditCorpName;

        if (corpName !== auditCorpName) {
            return (
                <>
                    <Alert color={'warning'} className="mt-3">{item.auditCorpName}</Alert>
                </>
            );
        } else {
            return item.auditCorpName
        }
    };

    const elevatorFormat = (cell, item) => {

        let elevatorCompany = item.elevatorCompany;
        let auditElevatorCompany = item.auditElevatorCompany;

        if (elevatorCompany !== auditElevatorCompany) {
            return (
                <>
                    <Alert color={'warning'} className="mt-3">{item.auditElevatorCompany}</Alert>
                </>
            );
        } else {
            return item.auditElevatorCompany
        }
    };

    const superFormat = (cell, item) => {

        let superName = item.superName;
        let auditSuperName = item.auditSuperName;

        if (superName != auditSuperName) {
            return (
                <>
                    <Alert color={'warning'} className="mt-3">{item.auditSuperName}</Alert>
                </>
            );
        } else {
            return item.auditSuperName
        }
    };

    const superPhoneFormat = (cell, item) => {

        let superPhone = item.superPhone;
        let auditSuperPhone = item.auditSuperPhone;

        if (superPhone !== auditSuperPhone) {
            return (
                <>
                    <Alert color={'warning'} className="mt-3">{item.auditSuperPhone}</Alert>
                </>
            );
        } else {
            return item.auditSuperPhone
        }
    };


    const showCheckFormatter = ((cell, item) => {

        // TODO: see if we can change the background when changed
        let trackAoc = item.trackAoc;
        let auditTrackAoc = item.auditTrackAoc;

        if (trackAoc !== auditTrackAoc) {
            if (cell === true) {
                return <Alert color={'warning'} className="mt-3"><Badge color={`soft-success`} style={{ width: '40px' }} className="rounded-capsule fs--1 d-block">
                    <FontAwesomeIcon icon='check' className="fs--4" color={'green'} /></Badge></Alert>
            } else {
                return <Alert color={'warning'} className="mt-3"><Badge color={`soft-danger`} style={{ width: '40px' }} className="rounded-capsule fs--1 d-block">
                    <FontAwesomeIcon icon='ban' className="fs--4" color={'red'} /></Badge></Alert >
            }
        } else {
            if (cell === true) {
                return <Badge color={`soft-success`} style={{ width: '40px' }} className="rounded-capsule fs--1 d-block">
                    <FontAwesomeIcon icon='check' className="fs--4" color={'green'} /></Badge>
            } else {
                return <Badge color={`soft-danger`} style={{ width: '40px' }} className="rounded-capsule fs--1 d-block">
                    <FontAwesomeIcon icon='ban' className="fs--4" color={'red'} /></Badge>
            }
        }
    });

    const showQceFormatter = ((cell, item) => {

        // TODO: see if we can change the background when changed
        let qce = item.qce;
        let auditQce = item.auditQce;
        if (item.isPeriodic === false) {
            if (qce !== auditQce) {
                if (cell === true) {
                    return <Alert color={'warning'} className="mt-3"><Badge color={`soft-success`} style={{ width: '40px' }} className="rounded-capsule fs--1 d-block">
                        <FontAwesomeIcon icon='check' className="fs--4" color={'green'} /></Badge></Alert>
                } else {
                    return <Alert color={'warning'} className="mt-3"><Badge color={`soft-danger`} style={{ width: '40px' }} className="rounded-capsule fs--1 d-block">
                        <FontAwesomeIcon icon='ban' className="fs--4" color={'red'} /></Badge></Alert >
                }
            } else {
                if (cell === true) {
                    return <Badge color={`soft-success`} style={{ width: '40px' }} className="rounded-capsule fs--1 d-block">
                        <FontAwesomeIcon icon='check' className="fs--4" color={'green'} /></Badge>
                } else {
                    return <Badge color={`soft-danger`} style={{ width: '40px' }} className="rounded-capsule fs--1 d-block">
                        <FontAwesomeIcon icon='ban' className="fs--4" color={'red'} /></Badge>
                }
            }
        } else {
            return <Badge color={`soft-secondary`} style={{ width: '50px' }} className="rounded-capsule fs--1 d-block">NA</Badge>
        }

    });


    const inspTypeColumn = ((cell, item) => {

        if (item.isPeriodic === true) {
            return <Alert color={'primary'} className="mt-3">
                Periodic
            </Alert>
        } else {
            return <Alert color={'info'} className="mt-3">
                Category</Alert >
        }

    });

    const cancelModal = () => {
        setShowModal(false);
    }

    const buttonFormatter = (column, colIndex, { sortElement, filterElement }) => {
        if (view === 'expiring') {

            return (
                <>
                    <Button size="sm" outline={true} className="pr-3 fs--1 mr-2" color={'success'} disabled={!canEdit} onClick={() => updateAll(1)}>
                        Approve
                        <FontAwesomeIcon icon="check" className="ml-1 fs--4" />
                    </Button>
                    <Button size="sm" outline={true} className="pr-3 fs--1 mr-2" color={'danger'} disabled={!canEdit} onClick={() => updateAll(2)}>
                        Decline
                        <FontAwesomeIcon icon="ban" className="ml-1 fs--4" />
                    </Button>
                </>
            );
        }

    };

    const yearFormat = (cell, item) => {

        let superPhone = item.superPhone;
        let auditSuperPhone = item.auditSuperPhone;

        if (item.agreementThroughYear <= moment(new Date).year()) {

            return <Alert color={'danger'} className="mt-3">{item.agreementThroughYear}</Alert>;
        } else {
            return <Alert color={'success'} className="mt-3">{item.agreementThroughYear}</Alert>;
        }


        // if (superPhone !== auditSuperPhone) {
        //     return (
        //         <>

        //         </>
        //     );
        // } else {
        //     return item.auditSuperPhone
        // }
    };

    const columns = [
        {
            dataField: 'agreementThroughYear',
            text: 'Agreement Through Year',
            classes: 'py-2 align-middle text-center',
            sort: true,
            formatter: yearFormat,
            headerStyle: (colum, colIndex) => {
                return { width: "5%", textAlign: "left" };
            },
        },
        {
            dataField: 'buildingAddress',
            text: 'Building Address',
            classes: 'py-2 px-2 align-middle text-left',
            sort: true,
            headerStyle: (colum, colIndex) => {
                return { width: "15%", textAlign: "left" };
            },
        },
        {
            dataField: 'auditCorpName',
            text: 'Corp Building Name',
            classes: 'py-2 align-middle text-left',
            sort: true,
            formatter: corpNameFormat,
            headerStyle: (colum, colIndex) => {
                return { width: "15%", textAlign: "left" };
            }
        },
        {
            dataField: 'device',
            text: 'Device',
            classes: 'py-2 align-middle text-left',
            sort: true,
            headerStyle: (colum, colIndex) => {
                return { width: "10%", textAlign: "left" };
            }
        },
        {
            dataField: 'isPeriodic',
            text: 'Inspection Type',
            classes: 'py-2 align-middle',
            sort: true,
            headerStyle: (colum, colIndex) => {
                return { width: "10%", textAlign: "left" };
            },
            formatter: inspTypeColumn

        },
        {
            dataField: 'auditTrackAoc',
            text: 'AOC Tracking',
            classes: 'py-2 align-middle text-center',
            sort: true,
            headerStyle: (colum, colIndex) => {
                return { width: "10%", textAlign: "left" };
            },
            formatter: showCheckFormatter
        },
        {
            dataField: 'auditQce',
            text: 'QCE',
            classes: 'py-2 align-middle text-center',
            sort: true,
            headerStyle: (colum, colIndex) => {
                return { width: "10%", textAlign: "left" };
            },
            formatter: showQceFormatter
        },
        {
            dataField: 'auditElevatorCompany',
            text: 'Elevator Company',
            classes: 'py-2 align-middle text-left',
            sort: true,
            formatter: elevatorFormat,
            headerStyle: (colum, colIndex) => {
                return { width: "15%", textAlign: 'left' };
            }
        },
        {
            dataField: 'auditSuperName',
            text: 'Super Name',
            classes: 'py-2 align-middle text-left',
            sort: true,
            formatter: superFormat,
            headerStyle: (colum, colIndex) => {
                return { width: "10%", textAlign: 'left' };
            }
        },
        {
            dataField: 'auditSuperPhone',
            text: 'Super Phone',
            classes: 'py-2 align-middle text-left',
            sort: true,
            formatter: superPhoneFormat,
            headerStyle: (colum, colIndex) => {
                return { width: "10%", textAlign: 'left' };
            }
        },
        {
            dataField: 'statusId',
            classes: 'align-middle text-right',
            headerClasses: 'border-0',
            text: '',
            headerFormatter: buttonFormatter,
            //formatter: (cell, item) => actionFormatter(cell, item),
            formatter: actionFormatter,
            headerStyle: (colum, colIndex) => {
                return { width: "15%", textAlign: 'right' };
            }
        }
    ];


    const modalUpdated = () => {
        setOriginalLines(lines);
        setPropLines(expiringLines);
        setShowModal(false);
    }

    return (
        <>

            {showModal === true && <DeviceModal
                showModal={showModal}
                modalUpdated={modalUpdated}
                cancelModal={cancelModal}
            />}

            {showLoader === true ? (
                <>
                    <Row noGutters>
                        <Col xl={12}>
                            <Card className="mb-3">
                                <CardBody>
                                    <Logo location={"auth"} logo={logo} />
                                    <Row className="m-5">
                                        <Col xs="12" style={{ alignSelf: 'center', textAlign: 'center' }}>
                                            <h3>Preparing your proposal.  One moment please......</h3>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </>

            ) : (<>


                <Row noGutters>
                    <Col xl={12}>
                        <Card className="mb-3">
                            <CardBody>
                                <Logo location={"auth"} logo={logo} />
                                {proposal.statusId === 3 && <Alert color="success text-center"><h3>Proposal Has Been Submitted!</h3></Alert>}
                                <Row className="m-2">
                                    <Col>
                                        <h5 className="text-center">2025 CATEGORY ONE INSPECTION/CATEGORY FIVE YEAR FULL LOAD SAFETY TESTS WITNESSING/PERIODIC INSPECTION/AOC TRACKING/QUALITY CONTROL EVALUATIONS</h5>
                                    </Col>
                                </Row>
                                <Row className="m-2">
                                    <Col>
                                        <p className="text-left">
                                            VDA is pleased to provide this extension agreement for your annual elevator inspections. This agreement includes the following services and is subject to terms and conditions:
                                        </p>
                                    </Col>
                                </Row>

                                <Row className="m-2">
                                    <Col>
                                        <ul>
                                            <li>Third Party Witness for Category 1 Inspection/Test and Category 5 Full Load Safety Inspection/Test. Please note NYC Department of Buildings (NYC DOB) regulations state that there must be a six (6) month interval between Category 1 Inspections/Tests.</li>
                                            <li>Third Party Performance of Periodic Inspections: NYC DOB regulations state that there must be a ninety (90) day interval between Periodic Inspections and Category 1 Inspections.</li>
                                            <li>Additional/Optional Services</li>
                                            <ul>
                                                <li><b>Affirmation of Corrections Tracking (AOC):</b> All inspections that report unsatisfactory results must be corrected and filed with NYC DOB within certain timeframes. AOC’s will be tracked for a period of one hundred ninety-four (194) days following the initial inspection/witness date.  This is consistent with the NYC DOB maximum allotted time for corrections should extensions be granted. Our compliance department will make every effort possible to work with the elevator company and building agent/owner to ensure that corrections are completed and filed within the allotted timeframes mandated by NYC DOB. VDA will not be responsible for late filings or penalties incurred because of late filings or failure to file.</li>
                                                <li><b>Expediting DOB Filing Fees:</b> NYC DOB filing fees are the responsibility of the Building Owner/Manager. Upon completion and attestation from all related parties, VDA will process the payment on your behalf (if the elevator is free from any elevator related penalty and/or fine). The DOB Filing fee will be passed through with an additional expediting fee for each filing fee paid.</li>
                                                <li><b>Quality Control Evaluation (QCE):</b> To be performed in conjunction with your category 1 Inspection/Test, VDA will provide a written report of your elevator maintenance conditions compared to industry standards, itemization of maintenance deficiencies, current operating performance times, known current and future code-mandated upgrade/alterations, system equipment profile, preliminary violation search as well as short- and long-term recommendations. Note that if a QCE is requested independent from the category inspection, a separate proposal will be provided with different rates.</li>
                                            </ul>
                                        </ul>

                                    </Col>
                                </Row>

                                <Row className="m-2">
                                    <Col>
                                        <p className="text-left">
                                            <u><b>Below is a list of buildings/devices/inspection types that are on file with our company (devices not listed will not be included in our services.  Owner/Agent must notify VDA of any additional devices prior to scheduling to meet all NYC DOB deadlines.  VDA will not be responsible for any fines/penalties incurred for devices not listed/approved). Please review the list and edit as necessary with the approve, decline, or edit functions. </b></u>
                                            <br /><br />You can simply add or remove options with the edit button and make changes as needed including adding or removing additional services, updating elevator maintenance company for building and onsite contact information. To edit a field, click edit and check approve (if not already checked) and/or make changes as needed.

                                        </p>
                                    </Col>
                                </Row>

                                <Row className="m-2">
                                    <Col>
                                        <p className="text-left">
                                            To ensure timely scheduling and compliance, please make sure you have approved and returned this proposal as well as your testing proposal submitted by your maintenance elevator contractor. This will enable us to schedule accordingly and within the NYC DOB rules. VDA is not responsible for notifying the building/super/resident manager of scheduled tests date(s).
                                        </p>
                                    </Col>
                                </Row>

                                {showErrorMessage == true && <Alert color="danger">{errorMessage}</Alert>}

                                <Row>
                                    <Col xs="12" style={{ alignSelf: 'center' }}>
                                        <Alert color="info" style={{ justifyItems: 'center', alignSelf: 'center' }}>

                                            <FormGroup>
                                                <Label>DOBNOW Email - <b>PLEASE CONFIRM DOBNOW EMAIL BELOW AND EDIT/PROVIDE ACCORDINGLY</b></Label>
                                                <Input
                                                    placeholder="DOBNOW Email"
                                                    disabled={!canEdit}
                                                    value={auditDobNowEmail ? auditDobNowEmail : ''}
                                                    onChange={(e) => setAuditDobNowEmail(e.target.value)}
                                                />
                                            </FormGroup>
                                        </Alert>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xl={4}>
                                        <div className="text-center">
                                            <Label>Customer</Label>
                                            <h5>{(proposal && proposal.customerName) ? proposal.customerName : ''}</h5>
                                        </div>
                                    </Col>
                                    <Col xl={4}>
                                        <div className="text-center">
                                            <Label>Agent</Label>
                                            <h5>{(proposal && proposal.agentName) ? proposal.agentName : ''}</h5>
                                        </div>
                                    </Col>
                                    <Col xl={4}>
                                        <div className="text-center">
                                            <Label>Email</Label>
                                            <h5>{(proposal && proposal.agentEmail) ? proposal.agentEmail : ''}</h5>
                                        </div>
                                    </Col>
                                </Row>
                            </CardBody>

                        </Card>
                    </Col>
                </Row>


                <Row noGutters>
                    <Col xl={12}>
                        <Card className="mb-3 text-center">
                            <CardBody>
                                <Row noGutters>
                                    <Col xl={12}>
                                        <Button
                                            className="fs-1 mr-2 text-600 text-white"
                                            color={view === 'expiring' ? 'primary' : 'secondary'}
                                            onClick={() => loadView('expiring')}
                                        >
                                            View Inspections Due to Renew ({expiringLines.length})
                                        </Button>

                                        <Button
                                            className="fs-1 mr-2 text-600 text-white"
                                            color={view === 'current' ? 'primary' : 'secondary'}
                                            onClick={() => loadView('current')}
                                        >
                                            View Inspections Under Contract ({underContractLines.length})
                                        </Button>

                                        <Button
                                            className="fs-1 mr-2 text-600 text-white"
                                            //className={classNames('fs-1 mr-2 text-600 text-white')}
                                            color={view === 'all' ? 'primary' : 'secondary'}
                                            onClick={() => loadView('all')}
                                        >
                                            View All Inspections ({lines.length})
                                        </Button>

                                        <div className="float-left">
                                            <Alert color='info'><h4 className="mt-2">Contract Term: {localPricing.contractTerm} year</h4></Alert>
                                        </div>
                                    </Col>
                                </Row>

                                {(view == 'expiring') && <Row noGutters>
                                    <Col xl={12} className="mt-3">
                                        <Row className="m-3">
                                            <Col >
                                                <h5 className="text-left">Cat 1 Fees</h5>
                                                <div className="text-left mb-3 mt-2">
                                                    <Label>Elevator</Label>
                                                    <h5>${(localPricing && localPricing.cat1ElevatorFee) ? localPricing.cat1ElevatorFee : ''}</h5>
                                                </div>
                                                <div className="text-left mb-3 mt-2">
                                                    <Label>Escalator</Label>
                                                    <h5>${(localPricing && localPricing.cat1EscalatorFee) ? localPricing.cat1EscalatorFee : ''}</h5>
                                                </div>
                                                <div className="text-left mb-3 mt-2">
                                                    <Label>Lift</Label>
                                                    <h5>${(localPricing && localPricing.cat1LiftFee) ? localPricing.cat1LiftFee : ''}</h5>
                                                </div>
                                                <div className="text-left mb-3 mt-2">
                                                    <Label>Filing</Label>
                                                    <h5>${(localPricing && localPricing.cat1FilingFee) ? localPricing.cat1FilingFee : ''}</h5>
                                                </div>
                                            </Col>
                                            <Col>
                                                <h5 className="text-left">Periodic Fees</h5>
                                                <div className="text-left mb-3 mt-2">
                                                    <Label>Elevator</Label>
                                                    <h5>${(localPricing && localPricing.periodicElevatorFee) ? localPricing.periodicElevatorFee : ''}</h5>
                                                </div>
                                                <div className="text-left mb-3 mt-2">
                                                    <Label>Escalator</Label>
                                                    <h5>${(localPricing && localPricing.periodicElevatorFee) ? localPricing.periodicElevatorFee : ''}</h5>
                                                </div>
                                                <div className="text-left mb-3 mt-2">
                                                    <Label>Lift</Label>
                                                    <h5>${(localPricing && localPricing.periodicElevatorFee) ? localPricing.periodicElevatorFee : ''}</h5>
                                                </div>
                                                <div className="text-left mb-3 mt-2">
                                                    <Label>Filing</Label>
                                                    <h5>${(localPricing && localPricing.periodicFilingFee) ? localPricing.periodicFilingFee : ''}</h5>
                                                </div>
                                            </Col>
                                            <Col >
                                                <h5 className="text-left">Cat 5 Fees</h5>
                                                <div className="text-left mb-3 mt-2">
                                                    <Label>Elevator</Label>
                                                    <h5>${(localPricing && localPricing.cat5ElevatorFee) ? localPricing.cat5ElevatorFee : ''}</h5>
                                                </div>
                                                <div className="text-left mb-3 mt-2">
                                                    <Label>Escalator</Label>
                                                    <h5>N/A</h5>
                                                </div>
                                                <div className="text-left mb-3 mt-2">
                                                    <Label>Lift</Label>
                                                    <h5>${(localPricing && localPricing.cat5LiftFee) ? localPricing.cat5LiftFee : ''}</h5>
                                                </div>
                                                <div className="text-left mb-3 mt-2">
                                                    <Label>Filing</Label>
                                                    <h5>${(localPricing && localPricing.cat5FilingFee) ? localPricing.cat5FilingFee : ''}</h5>
                                                </div>
                                            </Col>

                                            <Col >
                                                <h5 className="text-left">Dismantle Fees</h5>
                                                <div className="text-left mb-3 mt-2">
                                                    <Label>Elevator</Label>
                                                    <h5>${(localPricing && localPricing.dismantleElevatorFee) ? localPricing.dismantleElevatorFee : ''}</h5>
                                                </div>
                                                <div className="text-left mb-3 mt-2">
                                                    <Label>Escalator</Label>
                                                    <h5>${(localPricing && localPricing.dismantleElevatorFee) ? localPricing.dismantleElevatorFee : ''}</h5>
                                                </div>
                                                <div className="text-left mb-3 mt-2">
                                                    <Label>Lift</Label>
                                                    <h5>${(localPricing && localPricing.dismantleElevatorFee) ? localPricing.dismantleElevatorFee : ''}</h5>
                                                </div>
                                                <div className="text-left mb-3 mt-2">
                                                    <Label>Filing</Label>
                                                    <h5>${(localPricing && localPricing.dismantleFilingFee) ? localPricing.dismantleFilingFee : ''}</h5>
                                                </div>
                                            </Col>

                                            <Col >
                                                <h5 className="text-left">Additional Fees & Services</h5>
                                                <div className="text-left mb-3 mt-2">
                                                    <Label>Reschedule</Label>
                                                    <h5>${(localPricing && localPricing.rescheduleFee) ? localPricing.rescheduleFee : ''}</h5>
                                                </div>
                                                <div className="text-left mb-3 mt-2">
                                                    <Label>Overtime Rate</Label>
                                                    <h5>${(localPricing && localPricing.overtimeRate) ? localPricing.overtimeRate : ''}</h5>
                                                </div>
                                                <div className="text-left mb-3 mt-2">
                                                    <Label>Fire Service</Label>
                                                    <h5>${(localPricing && localPricing.fireServiceFee) ? localPricing.fireServiceFee : ''}</h5>
                                                </div>
                                            </Col>

                                            <Col >
                                                <h5>&nbsp;</h5>
                                                <div className="text-left mb-3 mt-2">
                                                    <Label>AOC Tracking Fee Per Device Per Inspection</Label>
                                                    <h5>${(localPricing && localPricing.aocFee) ? localPricing.aocFee : ''}</h5>
                                                </div>
                                                <div className="text-left mb-3">
                                                    <Label>Expediting Fee Per Device Per Inspection</Label>
                                                    <h5>${(localPricing && localPricing.expeditingFee) ? localPricing.expeditingFee : ''} per filing fee paid</h5>
                                                </div>
                                                <div className="text-left mb-3">
                                                    <Label>QCE Fee Per Device Per QCE Performed *</Label>
                                                    <h5>${(localPricing && localPricing.qceFee) ? localPricing.qceFee : ''} </h5>
                                                </div>

                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>}

                                <Row className="align-items-center">
                                    <Col className="col-6 text-left  py-2">
                                        <h5>Buildings List</h5>
                                    </Col>
                                    <Col className="col-6 text-right py-2">

                                    </Col>
                                </Row>
                                {/* 
                                <Alert color='info'><u><b>Attention:</b></u> Please be advised that your proposal will reflect an Agreement Through Year for each building address respectively.
                                    Agreement Through years noted in <Badge color={`soft-danger`} style={{ width: '40px' }} className="rounded-capsule fs--1">RED </Badge> will be expiring or have expired, and rates above will apply on renewal. Buildings listed with Agreement Through Date in
                                    {' '}<Badge color={`soft-success`} style={{ width: '60px' }} className="rounded-capsule fs--1">GREEN</Badge>{' '}
                                    are currently under contract through and including the year noted and will auto renew. You have ability to edit all buildings for additional services or update information as needed.
                                </Alert> */}





                                {view === 'expiring' && <div className="table-responsive">

                                    {expiringLines.length > 0 ? (<BootstrapTable
                                        bootstrap4
                                        keyField="proposalDetailId"
                                        data={expiringLines}
                                        columns={columns}
                                        bordered={false}
                                        classes="table-dashboard table-striped table-sm fs--1 border-bottom mb-0 table-dashboard-th-nowrap"
                                        rowClasses="btn-reveal-trigger"
                                        headerClasses="bg-200 text-900"
                                    />) : (
                                        <Alert color="info">No data to display</Alert>
                                    )}
                                </div>}

                                {view === 'current' && <div className="table-responsive">
                                    {underContractLines.length > 0 ? (<BootstrapTable
                                        bootstrap4
                                        keyField="proposalDetailId"
                                        data={underContractLines}
                                        columns={columns}
                                        bordered={false}
                                        classes="table-dashboard table-striped table-sm fs--1 border-bottom mb-0 table-dashboard-th-nowrap"
                                        rowClasses="btn-reveal-trigger"
                                        headerClasses="bg-200 text-900"
                                    />) : (
                                        <Alert color="info">No data to display</Alert>
                                    )}


                                </div>}

                                {view === 'all' && <div className="table-responsive">

                                    {lines.length > 0 ? (<BootstrapTable
                                        bootstrap4
                                        keyField="proposalDetailId"
                                        data={lines}
                                        columns={columns}
                                        bordered={false}
                                        classes="table-dashboard table-striped table-sm fs--1 border-bottom mb-0 table-dashboard-th-nowrap"
                                        rowClasses="btn-reveal-trigger"
                                        headerClasses="bg-200 text-900"
                                    />) : (
                                        <Alert color="info">No data to display</Alert>
                                    )}


                                </div>}




                                {/* <div className="table-responsive">
                                    {(lines) &&
                                        <BootstrapTable
                                            bootstrap4
                                            keyField="proposalDetailId"
                                            data={propLines}
                                            columns={columns}
                                            bordered={false}
                                            classes="table-dashboard table-striped table-sm fs--1 border-bottom mb-0 table-dashboard-th-nowrap"
                                            rowClasses="btn-reveal-trigger"
                                            headerClasses="bg-200 text-900"
                                        />
                                    }

                                </div> */}
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
















                <Row noGutters>
                    <Col xl={12}>
                        <Card className="mb-3">
                            <CardBody>
                                <Row>
                                    <Col xs="12" style={{ alignSelf: 'center' }}>
                                        <Alert color="info" style={{ justifyItems: 'center', alignSelf: 'center' }}>

                                            <FormGroup >
                                                <Label>Terms and Conditions</Label>
                                                <Scrollbar style={{ height: 150, backgroundColor: 'white', margin: 2 }}>
                                                    <div className="p-2">
                                                        <TermsAndConditionsV2 />



                                                    </div>
                                                </Scrollbar>

                                            </FormGroup>
                                        </Alert>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col xs="6" style={{ alignSelf: 'center' }}>
                                        <Alert color="info" style={{ justifyItems: 'center', alignSelf: 'center' }}>
                                            <FormGroup >
                                                <Label>Signature</Label>
                                                <Input
                                                    placeholder="Agent Signature"
                                                    disabled={!canEdit}
                                                    value={agentSignature ? agentSignature : ''}
                                                    onChange={(e) => setAgentSignature(e.target.value)}
                                                />
                                                <small>By Signing above, I agree to Sierra Consulting Group, Inc.'s Terms & Conditions</small>
                                            </FormGroup>
                                        </Alert>
                                    </Col>
                                    <Col xs="6" style={{ alignSelf: 'center' }}>
                                        <Alert color="info" style={{ justifyItems: 'center', alignSelf: 'center', height: 135 }}>
                                            <FormGroup >
                                                <Label>Expedite Filing Fee Agreement</Label>
                                                <Row className="mb-3">
                                                    <Col xs="12" md="2">
                                                        <Row xs="12" md="auto">
                                                            <Col xs="6" md="auto">
                                                                Yes
                                                            </Col>
                                                            <Col xs="6" md="auto">
                                                                <Input
                                                                    type="radio"
                                                                    name="expediteFee"
                                                                    placeholder="Yes"

                                                                    disabled={!canEdit}
                                                                    checked={agreedToExpeditingFee === true ? true : false}
                                                                    onChange={({ target }) => setAgreedToExpediteFee(true)}
                                                                />
                                                            </Col>
                                                        </Row>
                                                    </Col>

                                                    <Col xs="12" md="2">
                                                        <Row xs="12" md="auto">
                                                            <Col xs="6" md="auto">
                                                                No
                                                            </Col>
                                                            <Col xs="6" md="auto">
                                                                <Input
                                                                    type="radio"
                                                                    name="expediteFee"
                                                                    placeholder="No"

                                                                    disabled={!canEdit}
                                                                    checked={agreedToExpeditingFee === false ? true : false}
                                                                    onChange={({ target }) => setAgreedToExpediteFee(false)}
                                                                />
                                                            </Col>
                                                        </Row>
                                                    </Col>

                                                </Row>
                                                <small>*Required</small>
                                            </FormGroup>
                                        </Alert>
                                    </Col>
                                </Row>




                                {canEdit === true && <>
                                    <Row>
                                        <Col xs="12" style={{ alignSelf: 'center' }}>
                                            <Row className="mt-3 justify-content-center mb-3">
                                                <Col xs="auto">
                                                    <ReCAPTCHA
                                                        ref={captchaOptions.captchaRef}
                                                        sitekey={captchaOptions.siteKey}
                                                        onChange={handleCaptcha}
                                                    />
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs="12" style={{ alignSelf: 'center' }}>
                                            {showErrorMessage && <Alert color="danger">{errorMessage}</Alert>}
                                            <Row className="mt-3 justify-content-center mb-3">
                                                <Col xs="auto">
                                                    <Button
                                                        type="submit" className="btn-success btn-lg" onClick={handleSubmit}>Submit</Button>
                                                </Col>
                                            </Row>

                                        </Col>
                                    </Row>
                                </>}

                                {proposal.statusId === 3 && <Alert color="success text-center"><h3>Proposal Has Been Submitted!</h3></Alert>}

                            </CardBody>
                        </Card>
                    </Col>
                </Row>

            </>)}

        </>
    );
};

export default ProposalV2;
