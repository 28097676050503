import React, { useState, useEffect } from 'react';
import { Col, Row } from 'reactstrap';
import { VERSION } from '../services/appConstants';


// services
import * as versionApi from '../services/versionApi';

const Footer = () => {

	const [apiVersion, setApiVersion] = useState('0.0.0.0');

	useEffect(() => {
		versionApi.getApiVersion()
			.then((resp) => {
				setApiVersion(resp.data.version);
			});
	}, []);


	return (
		<footer>
			<Row noGutters className="justify-content-between text-center fs--1 mt-4 mb-3">
				<Col sm="auto">
					<p className="mb-0 text-600">
						ALL RIGHTS RESERVED <span className="d-none d-sm-inline-block"> | </span>
						<br className="d-sm-none" /> {new Date().getFullYear()} &copy; VDA
					</p>
				</Col>
				<Col sm="auto">
					<p className="mb-0 text-600">v {VERSION} - api version: {apiVersion}</p>
				</Col>
			</Row>
		</footer>
	)

};

export default Footer;
