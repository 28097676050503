import React from 'react';
import { Redirect, Route, Switch, withRouter, useParams } from 'react-router-dom';

import ExternalLayout from './layout/ExternalLayout';
import Proposal from './external/Proposal';
import Periodic from './external/Periodic';
import NewProposal from './external/NewProposal';
import ProposalV2 from './external/v2/proposal-v2';
import NotFound from './external/NotFound';
import Complete from './external/Complete';
import CustomerViolationReport from './external/CustomerViolationReport';

const Routes = () => {

	return (
		<ExternalLayout>
			<Switch location={window.location}>
				{/* <Route path="/" exact component={Survey} /> */}
				<Route path='/proposal/:id' component={Proposal} />
				<Route path='/periodic/:id' component={Periodic} />
				<Route path='/new-proposal/:id' component={NewProposal} />
				<Route path='/proposal-v2/:id' component={ProposalV2} />
				<Route path='/notFound' component={NotFound} />
				<Route path='/complete' component={Complete} />
				<Route path='/customer-violation-report/:id/:year' component={CustomerViolationReport} />
				<Redirect to='/notFound' />
			</Switch>
		</ExternalLayout>
	)
};

export default withRouter(Routes);