import { NEW_PROPOSAL as ActionTypes } from '../actionTypes';



const initialState = {
    proposal: {
        proposalId: '',
        batchId: 0,
        statusId: 1,
        statusDisplay: 'Sent',
        agentId: 0,
        agentName: '',
        agentLastName: '',
        agentEmail: '',
        dobNowEmail: '',
        auditDobNowEmail: '',
        customerId: 0,
        customerName: '',
        agreedToTermsAndConditions: '',
        year: '',
        superName: '',
        auditSuperName: '',
        dateCreated: '',
        isPeriodic: false,
        renewalCat1ElevatorFee: '0',
    },
    proposalDetails: {
        proposalDetailId: 0,
        proposalId: '',
        batchId: 0,
        statusId: 0,
        statusDisplay: '',
        inspectionId: 0,
        inspectionLineId: 0,
        buildingId: 0,
        buildingAddress: '',
        corpName: '',
        auditCorpName: '',
        elevatorCompanyId: 0,
        elevatorCompany: '',
        auditElevatorCompany: '',
        trackAoc: false,
        auditTrackAoc: '',
        qce: false,
        auditQce: false,
        superName: '',
        auditSuperName: '',
        superPhone: '',
        auditSuperPhone: '',
        device: '',
        year: '',
        agentId: 0,
        agentEmail: '',
        dateCreated: '',
        isPeriodic: false,
        agreementThroughYear: 0
    },
    lines: [],
    underContractLines: [],
    expiringLines: []
};

export const newProposalReducer = (state = initialState, action) => {

    switch (action.type) {
        case ActionTypes.SET_NEW_PROPOSAL_DETAIL:
            return {
                ...state,
                proposalDetails: action.payload
            };

        case ActionTypes.SET_NEW_PROPOSAL:

            return {
                ...state,
                proposal: action.payload.proposal,
                lines: action.payload.lines,
                expiringLines: action.payload.expiringLines,
                underContractLines: action.payload.underContractLines
            };

        case ActionTypes.SET_NEW_LINES:
            // used for approve all or reject all

            const currentYear2 = new Date().getFullYear();
            let expiringLines2 = action.payload.filter(f => f.agreementThroughYear == currentYear2);
            let underContractLines2 = action.payload.filter(f => f.agreementThroughYear > currentYear2);

            return {
                ...state,
                lines: action.payload,
                expiringLines: expiringLines2,
                underContractLines: underContractLines2
            };


        case ActionTypes.UPDATE_NEW_LINE:
            let newArray1 = [...state.lines];
            let index1 = newArray1.findIndex(c => c.proposalDetailId == action.payload.proposalDetailId);
            newArray1[index1] = action.payload; // updated line with updated status, could be 1 or 0

            const currentYear = new Date().getFullYear();
            let expiringLines = newArray1.filter(f => f.agreementThroughYear == currentYear);
            let underContractLines = newArray1.filter(f => f.agreementThroughYear > currentYear);

            return {
                ...state,
                lines: newArray1,
                expiringLines: expiringLines,
                underContractLines: underContractLines
            };

        case ActionTypes.UPDATE_NEW_PROPOSAL:
            // used to update the status of the prop from sent to viewed and from viewed to submitted
            return {
                ...state,
                proposal: action.payload
            };

        default:

            return state;
    };
}