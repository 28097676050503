import { API_METHOD, ENDPOINTS } from './appConstants';
import { handleFetch } from './handleFetch';

const apiRoot = ENDPOINTS.API + '/NewProposal';


export const getNewManifest = (proposalId) => {

    let url = `${apiRoot}/GetNewManifest/${proposalId}`;

    const options = {
        method: API_METHOD.GET
    };
    return handleFetch(url, options, true);
}

export const getNewManifestV2 = (proposalId) => {

    let url = `${apiRoot}/GetNewManifestV2/${proposalId}`;

    const options = {
        method: API_METHOD.GET
    };
    return handleFetch(url, options, true);
}



export const submit = (data) => {

    let url = `${apiRoot}/NewSubmit`;

    const options = {
        method: API_METHOD.POST,
        body: JSON.stringify(data)
    };
    return handleFetch(url, options, true);
}



